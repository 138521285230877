<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/profile"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.profileDevicePersmissions.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div class="ion-padding ion-text-center">
        <div style="font-size: 48px">
          <ion-icon
            :src="`${s3Url}/medias/images/icons/icon-phone-shield.svg`"
            slot="start"
          ></ion-icon>
        </div>
        {{ $t('views.profileDevicePersmissions.description') }}
      </div>
      <ion-list lines="none">
        <!-- Camera -->
        <ion-item button @click="onRequestCameraPermissions">
          <ion-icon :icon="icons.cameraOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('labels.camera') }}
          </ion-label>
          <ion-icon
            v-if="!loading && cameraPermission === 'granted'"
            :icon="icons.checkmarkOutline"
            slot="end"
            color="success"
          ></ion-icon>
          <ion-icon
            v-else-if="!loading && !cameraPermission !== 'granted'"
            :icon="icons.closeOutline"
            slot="end"
            color="danger"
          ></ion-icon>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Camera -->
        <ion-item button @click="onRequestCameraPermissions">
          <ion-icon :icon="icons.imagesOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('labels.photos') }}
          </ion-label>
          <ion-icon
            v-if="!loading && photosPermission === 'granted'"
            :icon="icons.checkmarkOutline"
            slot="end"
            color="success"
          ></ion-icon>
          <ion-icon
            v-else-if="!loading && !photosPermission !== 'granted'"
            :icon="icons.closeOutline"
            slot="end"
            color="danger"
          ></ion-icon>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from '@ionic/vue';

import { Camera } from '@capacitor/camera';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
} from '@ionic/vue';

import {
  cameraOutline,
  imagesOutline,
  checkmarkOutline,
  closeOutline,
} from 'ionicons/icons';

export default {
  name: 'ProfileDevicePermissions',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonSpinner,
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      loading: true,
      cameraPermission: null,
      photosPermission: null,
      icons: {
        cameraOutline,
        imagesOutline,
        checkmarkOutline,
        closeOutline,
      },
    };
  },
  async ionViewDidEnter() {
    this.checkPermissions();
  },
  methods: {
    async checkPermissions() {
      this.loading = true;

      const { camera, photos } = await Camera.checkPermissions();

      this.cameraPermission = camera;
      this.photosPermission = photos;

      this.loading = false;
    },
    async onRequestCameraPermissions() {
      if (isPlatform('capacitor')) {
        await Camera.requestPermissions(['camera', 'photos']);
        this.checkPermissions();
      }
    },
  },
};
</script>
